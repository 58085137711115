import React from 'react';
import { detect } from 'detect-browser';
import SpidermanVideo from '../../videos/technoterca.mp4';
import { Section, Video } from './styles';

function BackgroundVideo() {
    // Get the browser name
    const browser = detect();

    return (
        <Section className="bg-video">
            {
                // If the browser is not Safari, render the video component
                (browser && browser.name !== 'safari') && (
                    <Video autoPlay muted loop playsInline webkit-playsinline>
                        <source src={SpidermanVideo} type="video/mp4" />
                    </Video>
                )
            }
        </Section>
    );
}

export default BackgroundVideo;
