import React, {useState} from 'react';

import {
    StyledHeader,
    Nav,
    NavMenu,
    // Logo,
    NavList,
    NavLink,
    NavSocial,
    FacebookIcon,
    // InstagramIcon,
    TwitterIcon,
    YouTubeIcon,
    BurgerMenu,
    NavListMobile,
} from './styles';

function Header() {
    const [burgerMenuActive, setBurgerMenuActive] = useState(false);

    const handleMenuToogle = () => {
        setBurgerMenuActive(!burgerMenuActive);
    };

    return (
        <StyledHeader>
            <Nav>
                <NavMenu>
                    {/*<Logo className="logo"/>*/}

                    <NavList className="nav-list">
                        <li>
                            <NavLink>
                              <a href="https://form.typeform.com/to/hHvHpvao" target="_blank" rel="noopener noreferrer">
Eventos                              </a>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink>
                              <a href="https://form.typeform.com/to/FmroYiCA" target="_blank" rel="noopener noreferrer">
                                DJs
                              </a>

                    </NavLink>
                </li>
                {/*<li>*/}
                {/*  <NavLink>Calendar</NavLink>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  <NavLink className="force-red">#BeYourself</NavLink>*/}
                {/*</li>*/}
            </NavList>
        </NavMenu>

    <NavSocial className="nav-social">
        {/*<FacebookIcon />*/}

        {/*<a href="https://www.instagram.com/technoterca/" target="_blank" rel="noopener noreferrer">*/}
        {/*    <InstagramIcon/>                              </a>*/}

        {/*<TwitterIcon />*/}
        {/*<YouTubeIcon />*/}
    </NavSocial>

    <BurgerMenu
        className={`${burgerMenuActive ? 'opened' : 'closed'} burger-menu`}
        onClick={handleMenuToogle}
    />

    <NavListMobile className={`${burgerMenuActive ? 'opened' : 'closed'}`}>
        <li>
            <NavLink>

              <a href="https://form.typeform.com/to/hHvHpvao" target="_blank" rel="noopener noreferrer">
Eventos              </a>

            </NavLink>
        </li>
        <li>
            <NavLink>

              <a href="https://form.typeform.com/to/FmroYiCA" target="_blank" rel="noopener noreferrer">
DJs              </a>
            </NavLink>
        </li>
        {/*<li>*/}
        {/*  <NavLink>Calendar</NavLink>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*  <NavLink>#BeYourself</NavLink>*/}
        {/*</li>*/}
    </NavListMobile>
</Nav>
</StyledHeader>
)
    ;
}

export default Header;
